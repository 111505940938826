import React, { useContext, useMemo } from 'react';

import { PivotControls } from 'components/dashboard/Metrics/Widget/Controls/PivotControls';
import { groupColumns } from 'components/dashboard/Metrics/Widget/Controls/helpers';
import { FORECAST_SUBMISSION_OBJECTS } from 'components/dashboard/Metrics/constants';
import { RevBISettingsContext } from 'components/dashboard/Metrics/contexts/RevBISettingsContext';
import {
  VisualizationType,
  VisualizationTypeOneDimension,
} from 'components/dashboard/Metrics/enums';
import {
  DataDescriptor,
  BIMetricSimple,
  BIMetricToChartType,
  BIPivotDescriptor,
  BIWidget,
} from 'components/dashboard/Metrics/metrics.types';

interface Props {
  readonly isTargetMetricOnlyWidget: boolean;
  readonly hasTargetMetric: boolean;
  readonly availablePivots: BIPivotDescriptor[];
  readonly widget: BIWidget;
  readonly metricToChartType: BIMetricToChartType[];
  setWidget: (widget: BIWidget) => void;
}

export const MetricsWidgetControls: React.FC<Props> = ({
  isTargetMetricOnlyWidget = false,
  hasTargetMetric = false,
  availablePivots,
  widget,
  metricToChartType,
  setWidget,
}) => {
  const { hierarchyAlias, tableAliasesConfigs } =
    useContext(RevBISettingsContext);

  const isPieChart = metricToChartType.some(
    (el) => el.chartType === VisualizationType.Pie
  );

  const [dateTypeOptionsPivot, availableExtendedPivots] = useMemo(() => {
    const dateTypeColumns = availablePivots.filter(
      (column) => column.type === 'date'
    );

    // remove this line ones we implements the new Date EPIC
    // and remove the function.
    const columns = groupColumns(availablePivots);

    // if current visualization is pie we remove the user.name
    let availableExtendedPivots = isPieChart
      ? columns.filter((e) => !hierarchyAlias.includes(e.name ?? ''))
      : columns;

    // populate the table icon.
    availableExtendedPivots = availableExtendedPivots
      .map((pivot) => {
        if (pivot.table_name) {
          pivot.table_icon =
            tableAliasesConfigs[pivot.table_name] ?? pivot.table_name;
        }
        return pivot;
      })
      .sort(
        (a, b) =>
          (a.table_name?.localeCompare(b.table_name ?? '') ?? 0) ||
          a.label.localeCompare(b.label)
      );

    return [dateTypeColumns, availableExtendedPivots];
  }, [availablePivots, isPieChart, tableAliasesConfigs]);

  const hasFSMetric: boolean = useMemo(
    () =>
      Boolean(
        widget.metric_list &&
          widget.metric_list.length &&
          widget.metric_list.some((metric: BIMetricSimple) =>
            FORECAST_SUBMISSION_OBJECTS.includes(metric.object)
          )
      ),
    [JSON.stringify(widget.metric_list)]
  );

  const isTableVisualization: boolean = metricToChartType.some(
    (el) => el.chartType === VisualizationType.Table
  );

  const handlePivotChange = (values: string[], pivotNro: number = 0): void => {
    // If the values is none we should cut the group by array from that position.
    if (values[0] === 'none') {
      let newMetricToChartType = [...(metricToChartType ?? [])];
      if (pivotNro === 0) {
        // if removes the first pivot by default the viz mode is column
        newMetricToChartType = newMetricToChartType.map((e) => ({
          ...e,
          chartType: VisualizationType.Table,
        }));
      } else if (pivotNro === 1) {
        // if removes the second pivot and viz mode is stacked columns, we should map to column mode.
        newMetricToChartType = newMetricToChartType.map((e) => ({
          ...e,
          chartType:
            e.chartType === VisualizationType.ColumnStacked
              ? VisualizationType.Column
              : e.chartType,
        }));
      }

      setWidget({
        ...widget,
        group_by: widget.group_by?.splice(0, pivotNro) ?? [],
        properties: {
          metricToChartType: newMetricToChartType,
        },
      });
      return;
    }

    const newFilters = widget.template_filters || [];
    const column = availableExtendedPivots.find(
      (column) => column.name === values[0]
    );

    const isDateTypePivot = ['date', 'timePeriod'].includes(column?.type ?? '');

    let targetDateIndex = -1;

    if (isDateTypePivot && hasTargetMetric) {
      const idx = widget.template_filters?.findIndex(
        (f) => f.column.name === 'target.target_date'
      );
      targetDateIndex = idx ?? -1;

      if (targetDateIndex > -1) {
        newFilters.splice(targetDateIndex, 1);
      }
    }

    let newGroupBy = [...(widget?.group_by ?? [])];
    let newWidget: Partial<BIWidget> = {};

    // when we remove the date pivots support we need to remove this.
    if (column?.type === 'timePeriod') {
      newGroupBy = [
        column.label.includes('MoM')
          ? {
              name: 'month',
              label: 'Month',
              type: 'date',
              table_name: '',
            }
          : column.label.includes('QoQ')
          ? {
              name: 'quarter',
              label: 'Quarter',
              type: 'date',
              table_name: '',
            }
          : { name: 'week', label: 'Week', type: 'date', table_name: '' },
      ];

      let timeField: Partial<BIPivotDescriptor> =
        dateTypeOptionsPivot.find((timePeriod) =>
          column.name.includes(timePeriod.name)
        ) ?? {};

      newWidget = {
        time_field: timeField as DataDescriptor,
      };
    } else if (column) {
      let metricToChartType = [
        ...(widget.properties?.metricToChartType ?? [
          {
            metricId: widget.metric_list[0]._id,
            chartType: VisualizationType.Table,
          },
        ]),
      ];
      const isHierarchyPivot = hierarchyAlias.includes(column?.name ?? '');
      const OneDimensionKeys = Object.keys(VisualizationTypeOneDimension).map(
        (t) => t.toLocaleLowerCase()
      );
      const hasOneDimensionType = metricToChartType.some((el) =>
        OneDimensionKeys.includes(el.chartType.toLocaleLowerCase())
      );

      newGroupBy[pivotNro] = column;

      // if the pivot is related to hierarchy
      // is not allow to visualize as a pie chart
      if (isHierarchyPivot && isPieChart && metricToChartType) {
        metricToChartType = metricToChartType.map((mtc) => ({
          ...mtc,
          chartType: VisualizationType.Table,
        }));
      }
      if (pivotNro === 0) {
        metricToChartType = metricToChartType.map((mtc) => ({
          ...mtc,
          chartType: OneDimensionKeys.includes(mtc.chartType)
            ? mtc.chartType
            : VisualizationType.Table,
        }));
      }
      //si es 0 el que estoy cambiand ,cambio los stacked por column
      // if the pivot #2 is different than none,
      // by default the chart should visualized as column stacked by default.
      if (pivotNro === 1 && hasOneDimensionType && metricToChartType) {
        metricToChartType = metricToChartType.map((mtc) => ({
          ...mtc,
          chartType: VisualizationType.ColumnStacked,
        }));
      }
      // if the pivot #3 is different than none,
      // or the widget has more than 4 metric
      // by default the widget should visualize as table.
      if (
        (pivotNro === 2 || widget.metric_list.length > 4) &&
        metricToChartType
      ) {
        metricToChartType = metricToChartType.map((mtc) => ({
          ...mtc,
          chartType: VisualizationType.Table,
        }));
      }

      newGroupBy = [...(newGroupBy.splice(0, pivotNro + 1) ?? [])];
      newWidget = {
        ...(pivotNro === 0 ? { time_field: null } : {}),
        properties: {
          metricToChartType,
        },
      };
    }

    setWidget({
      ...widget,
      ...newWidget,
      group_by: newGroupBy,
      ...(targetDateIndex > -1 && {
        template_filters: newFilters,
      }),
    });
  };

  return (
    <PivotControls
      availablePivots={availableExtendedPivots}
      hasFSMetric={hasFSMetric}
      groups={widget.group_by ?? []}
      isPieChart={isPieChart}
      isTargetMetricOnlyWidget={isTargetMetricOnlyWidget}
      timeField={widget.time_field}
      onPivotChange={handlePivotChange}
    />
  );
};
