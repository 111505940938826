import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  CompanyUserUpdateFields,
  updateCompanyUsers,
  fetchCompanyUsers,
} from 'api/users';
import { useDispatch } from 'react-redux';
import {
  CompanyUsersQueryParams,
  getCompanyManagers,
  getCompanyManagerTeams,
} from 'actions/companyUsersActions';
import { toast } from 'react-toastify';

const USERS_QUERY_KEY = 'CompanyUsers' as const;

export const useManageCompanyUsers = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const updateUsersMutation = useMutation({
    mutationFn: (users: CompanyUserUpdateFields[]) => updateCompanyUsers(users),
    onMutate: () => {
      toast.warn('Update in progress.', { position: 'bottom-left' });
    },
    onSuccess: (response) => {
      const updatedUsers = Object.values(response);

      // Update the query data with the new users
      queryClient.setQueriesData([USERS_QUERY_KEY], (old: any) => {
        if (!old?.users) return old;

        return {
          ...old,
          users: old.users.map((user: any) => {
            const updatedUser = updatedUsers.find(
              (u: any) => u.email === user.email
            );
            return updatedUser || user;
          }),
        };
      });

      // Check for sync schedule failure
      const syncScheduleFailed = updatedUsers.some(
        (user: any) => user.sync_schedule_failed
      );
      if (syncScheduleFailed) {
        toast.error(
          `New email/calendar sync failed. We have sent an alert to our customer 
          success team and they could contact you shortly. Alternatively, 
          you can contact our support team to get it resolved.`,
          { position: 'bottom-left', delay: 2000 }
        );
      }

      // We should migrate this to react query
      dispatch(getCompanyManagers());
      dispatch(getCompanyManagerTeams());

      toast.success('Update successful.', { position: 'bottom-left' });
    },

    onError: (error) => {
      const errorMessage = error ?? 'Unexpected failure.';
      toast.error(errorMessage);
    },
  });

  const updateUsers = async (users: CompanyUserUpdateFields[]) => {
    return updateUsersMutation.mutateAsync(users);
  };

  const updateUser = async (user: CompanyUserUpdateFields) => {
    return updateUsers([user]);
  };

  return {
    updateUser,
    updateUsers,
    isUpdating: updateUsersMutation.isLoading,
  };
};

export const useCompanyUsers = (params: CompanyUsersQueryParams) => {
  return useQuery({
    queryKey: [USERS_QUERY_KEY, params],
    queryFn: ({ signal }) => fetchCompanyUsers(params, { signal }),
  });
};

export const useInvalidateCompanyUsers = () => {
  const queryClient = useQueryClient();
  return () => queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] });
};
