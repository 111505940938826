import { CompanyUsersQueryParams } from 'actions/companyUsersActions';
import axios from 'axios';

export interface SfdcUserId {
  id: string;
  selected: boolean;
}

export interface AccessToken {
  status: 'missing' | 'expired';
  expiry: null;
}

export interface UserSettings {
  processing: {
    calendar: boolean;
    calls: boolean;
    emails: boolean;
  };
  email_keyword_analysis_report: boolean;
  email_periodical_manager_report: boolean;
  email_call_summary: boolean;
}

export interface Profile {
  name: string;
  id: string;
}

export interface Manager {
  name: string;
  id: string;
  email: string;
}

export interface ImpersonationSetting {
  scope: string;
  access_mode: string;
}

export interface User {
  status: 'active' | 'disabled';
  name: string;
  access_token: AccessToken;
  created_time: string;
  force_next_email_sync: boolean;
  user_settings: UserSettings;
  role: string;
  force_next_calendar_sync: boolean;
  impersonation_settings: ImpersonationSetting[];
  calendar_watcher_last_processed_failed: boolean;
  sync_schedule_failed: boolean;
  allow_salesforce_update: boolean;
  sfdc_user_id: SfdcUserId[];
  crm_active: boolean;
  profile?: Profile;
  id: string;
  email_watcher_last_processed_failed: boolean;
  manager?: Manager;
  email_watcher_refreshed_failed: boolean;
  email: string;
  created_by: string;
  calendar_watcher_refreshed_failed: boolean;
}

export interface CompanyUsersResponse {
  users: User[];
  total: number;
  disable_fields: string[];
  email_domains: string[];
}

export interface CompanyUsersResponseData {
  data: CompanyUsersResponse;
}

export const fetchCompanyUsers = async (
  params: CompanyUsersQueryParams,
  { signal }: { signal?: AbortSignal }
) => {
  const response = await axios.get<CompanyUsersResponseData>(
    `/api/settings/company/users`,
    {
      params,
      signal,
    }
  );
  return response.data.data;
};

export interface CompanyUserUpdateFields {
  name?: string;
  email?: string;
  new_email?: string;
  role?: string;
  status?: string;
  manager?: string | null;
  profile?: string | null;
  impersonation_settings?: ImpersonationSetting[];
  allow_salesforce_update?: boolean;
  sfdc_user_id?: string;
  user_settings?: {
    processing?: {
      calendar?: boolean;
      calls?: boolean;
      emails?: boolean;
    };
  };
}

export interface CompanyUserUpdateResponse {
  data: {
    [key: string]: User | string;
  };
}

export const updateCompanyUsers = async (data: CompanyUserUpdateFields[]) => {
  const response = await axios.put<CompanyUserUpdateResponse>(
    `/api/settings/company/users`,
    data
  );

  const responseData = response.data.data;

  // Backend always returns 200, if something breaks its send as a message
  // inside the user that caused it as a string
  const updatedUsers = Object.values(responseData);
  const userWithError = updatedUsers.find(
    (u) => typeof u === 'string'
  ) as string;

  if (userWithError) {
    throw new Error(userWithError);
  }

  return responseData as Record<string, User>;
};
