import * as _ from 'ramda';

import { ENABLED_ANALYTICS_TRACKERS } from 'common/constants';
import { notNullAndEmpty } from 'common/utils';

const pendo = window ? window.pendo : null;

export class AnalyticsTracker {
  static init() {}
  static event(page_props, event_props) {
    const { category, ...rest } = event_props;

    if (notNullAndEmpty(page_props) && page_props.isAdmin) {
      rest.is_admin = 1;
    } else {
      rest.is_admin = 0;
    }

    if (ENABLED_ANALYTICS_TRACKERS.pendo && pendo) {
      pendo.track(category, rest);
    }
  }

  static pageview(page_props) {
    const url = window.location.pathname + window.location.search;

    const event_props = { url };

    if (notNullAndEmpty(page_props) && page_props.isAdmin) {
      event_props.is_admin = 1;
    } else {
      event_props.is_admin = 0;
    }
  }

  static getCompany(email) {
    if (!_.isNil(email)) {
      const idx = email.indexOf('@');

      if (idx !== -1) {
        return email.substring(idx + 1);
      }
    }

    return null;
  }

  static setUserProperties(props) {
    const { user, isAdmin } = props;
    const { id, email, role, name } = user;
    const company = this.getCompany(email);
    const user_props = isAdmin
      ? {
          email: 'prod-admin@boostup.ai',
          name: 'Prod Admin User',
          role: 'Admin',
          company: 'BoostUp',
          assumed_user_email: email,
          assumed_user_name: name,
          assumed_user_role: role,
          assumed_user_company: company,
        }
      : {
          email,
          name,
          role,
          company,
        };
  }
}

export default AnalyticsTracker;
