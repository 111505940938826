import {
  connect,
  MapDispatchToPropsParam,
  MapStateToPropsParam,
} from 'react-redux';

import { createCompanyUsers } from 'actions/companyUsersActions';
import UsersAddView from 'components/settings/ManageUsers/UsersAddView/UsersAddView';
import {
  IDispatchProps,
  IOwnProps,
  IStateProps,
} from 'components/settings/ManageUsers/UsersAddView/types';
import { IReduxState } from 'reducers/types';
import {
  getCompanyManagerList,
  isCompanyUserCreated,
} from 'selectors/companyUsers';

export const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IOwnProps,
  IReduxState
> = (state) => ({
  isCreated: isCompanyUserCreated(state),
  managers: getCompanyManagerList(state),
});

export const mapDispatchToProps: MapDispatchToPropsParam<
  IDispatchProps,
  IOwnProps
> = {
  createUsers: createCompanyUsers,
};

export default connect(mapStateToProps, mapDispatchToProps)(UsersAddView);
