import { connect, MapStateToPropsParam } from 'react-redux';

import UsersBulkUpdate from 'components/settings/ManageUsers/UsersBulkUpdate/UsersBulkUpdate';
import {
  IOwnProps,
  IStateProps,
} from 'components/settings/ManageUsers/UsersBulkUpdate/types';
import { IReduxState } from 'reducers/types';
import { isAdminEnabled } from 'selectors';
import { getCompanyManagerList } from 'selectors/companyUsers';

export const mapStateToProps: MapStateToPropsParam<
  IStateProps,
  IOwnProps,
  IReduxState
> = (state) => ({
  managers: getCompanyManagerList(state),
  isAdminEnabled: isAdminEnabled(state),
});

export default connect(mapStateToProps)(UsersBulkUpdate);
