import { SubValuesData } from 'api/RevBiDropdownConfigs';
import classNames from 'classnames';
import {
  InfoIcon,
  InfoIconWrapper,
  MetricPropertyWrapper,
  SavedMetricProperty,
} from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/DefinitionsMetricsList/styles';
import { debounce } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';

import BuCheckbox from 'components/UI/BuCheckbox';
import BuInput from 'components/UI/BuInput';

import BuRadio from 'components/UI/BuRadio';
import MetricColorPicker from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/DefinitionsMetricsList/MetricProperties/MetricColorPicker';
import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  BIMetricCreated,
  MetricDisplayInfo,
  MetricsDisplay,
} from 'components/dashboard/Metrics/metrics.types';
import { SubValueConfigurationComponent } from './SubValueConfigurationComponent';
import BuPopup from 'components/UI/BuPopup';
import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import { radioGroup } from 'components/dashboard/Metrics/Create/WidgetCreate/WidgetCreateOptions/DefinitionsMetricsList/MetricProperties/styles';

interface IProps {
  readonly metric: BIMetricCreated;
  readonly metricsDisplayConfig: MetricsDisplay | undefined;
  subValuesData: SubValuesData | undefined;
  isLoadingSubValueList: boolean;
  onChange: (metricId: string, config: MetricDisplayInfo) => void;
}

export const MetricProperty: React.FC<IProps> = ({
  metricsDisplayConfig,
  metric,
  subValuesData,
  isLoadingSubValueList,
  onChange,
}) => {
  /**
   * for now only one section is only available for Live type
   * if in the future we have more section please use this dictionary.
   */
  const propertyEnableSettings = useMemo(() => {
    const isLive = metric.analysis_type === AnalysisType.LIVE;

    return {
      subValue: isLive,
    };
  }, [metric]);

  const metricDisplayConfig: MetricDisplayInfo = useMemo(
    () => (metricsDisplayConfig ? metricsDisplayConfig[metric._id] : {}),
    [metricsDisplayConfig, metric._id]
  );
  const subValue = useMemo(() => {
    if (metricDisplayConfig) {
      const key = Object.keys(metricDisplayConfig.subvalues ?? {})[0];
      if (key && metricDisplayConfig.subvalues) {
        return metricDisplayConfig.subvalues[key];
      }
    }
  }, [metricDisplayConfig]);

  const [showSubValue, setShowSubValue] = useState(!!subValue);

  const [displayName, setDisplayName] = useState<string>(
    metricDisplayConfig?.display_name_override ?? metric.name
  );

  const [subValueTemplate, setSubValueTemplate] = useState<string>(
    subValue?.template ?? ''
  );

  const triggerDelayedChange = useCallback(
    debounce((metricId: string, value: string) => {
      onChange(metricId, { display_name_override: value });
    }, 750),
    [onChange]
  );

  const triggerDelayedSubValueTemplateChange = useCallback(
    debounce((metricId: string, value: string) => {
      if (subValue) {
        onChange(metricId, {
          subvalues: {
            [subValue.field_name]: { ...subValue, template: value },
          },
        });
      }
    }, 750),
    [onChange]
  );

  const canEnableDeltas = useMemo(() => {
    return metric.analysis_type === AnalysisType.LIVE;
  }, [metric]);

  const canSetDecimal = metric.analysis_type === AnalysisType.LIVE;

  return (
    <>
      <SavedMetricProperty>
        <MetricPropertyWrapper>
          <BuCheckbox
            checked={!!metricDisplayConfig?.column_highlight_color}
            onChange={(value) => {
              onChange(metric._id, {
                column_highlight_color: value ? '#FFFFFF' : undefined,
              });
            }}
            elementLabel={
              <label>Add background color to Highlight Column</label>
            }
          />
          <MetricColorPicker
            color={metricDisplayConfig?.column_highlight_color}
            onChange={(value: string) => {
              onChange(metric._id, { column_highlight_color: value });
            }}
            disabled={!metricDisplayConfig?.column_highlight_color}
          />
        </MetricPropertyWrapper>
      </SavedMetricProperty>

      <SavedMetricProperty>
        <label>Display as</label>
        <BuInput
          maxLength={100}
          value={displayName}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.currentTarget.value;
            setDisplayName(value);
            triggerDelayedChange(metric._id, value);
          }}
        ></BuInput>
      </SavedMetricProperty>
      <SavedMetricProperty>
        <BuCheckbox
          checked={metricDisplayConfig?.display_total ?? true}
          onChange={(value) => {
            onChange(metric._id, { display_total: value });
          }}
          elementLabel={<label>Add this as a top metric</label>}
        />
      </SavedMetricProperty>
      {propertyEnableSettings.subValue && (
        <SavedMetricProperty>
          <BuCheckbox
            checked={showSubValue}
            onChange={(value) => {
              if (!value) {
                setSubValueTemplate('');
                onChange(metric._id, {
                  ...metricDisplayConfig,
                  subvalues: undefined,
                });
              }
              setShowSubValue(value);
            }}
            elementLabel={<label>Show additional/sub value</label>}
          />
          <SubValueConfigurationComponent
            className={classNames({ active: showSubValue })}
            isLoadingSubValueList={isLoadingSubValueList}
            metricDisplayConfig={metricDisplayConfig}
            metric={metric}
            subValue={subValue}
            subValuesData={subValuesData}
            onChange={onChange}
            triggerDelayedSubValueTemplateChange={
              triggerDelayedSubValueTemplateChange
            }
          />
        </SavedMetricProperty>
      )}
      {canEnableDeltas && (
        <SavedMetricProperty>
          <div className={InfoIconWrapper}>
            <BuCheckbox
              checked={metricDisplayConfig?.show_deltas ?? false}
              onChange={(value) => {
                onChange(metric._id, { show_deltas: value });
              }}
              elementLabel={<label>Add Deltas</label>}
            />
            <BuPopup
              trigger={(ref) => (
                <div ref={ref}>
                  {
                    <BuIcon
                      name={BoostUpIcons.BadgeInfoOutline}
                      color={'var(--bu-gray-900)'}
                    />
                  }
                </div>
              )}
            >
              <div className={InfoIcon}>
                Enable deltas in table visualization
              </div>
            </BuPopup>
          </div>
        </SavedMetricProperty>
      )}

      {canSetDecimal && (
        <SavedMetricProperty>
          <div className={InfoIconWrapper}>
            <BuCheckbox
              checked={!!metricDisplayConfig?.decimals}
              onChange={(value) => {
                onChange(metric._id, { decimals: value ? 1 : 0 });
              }}
              elementLabel={<label>Show decimals place</label>}
            />
            <BuPopup
              trigger={(ref) => (
                <div ref={ref}>
                  {
                    <BuIcon
                      name={BoostUpIcons.BadgeInfoOutline}
                      color={'var(--bu-gray-900)'}
                    />
                  }
                </div>
              )}
            >
              <div className={InfoIcon}>
                Enable decimal place in table visualization. If you've added
                sub-values or deltas to your view, decimals will apply to those
                too.
              </div>
            </BuPopup>
          </div>
          {!!metricDisplayConfig?.decimals && (
            <div className={radioGroup}>
              <BuRadio
                checked={metricDisplayConfig?.decimals === 1}
                onChange={() => {
                  onChange(metric._id, { decimals: 1 });
                }}
                label="One decimal"
                name={`decimal-config-${metric._id}`}
              />
              <BuRadio
                checked={metricDisplayConfig?.decimals === 2}
                onChange={() => {
                  onChange(metric._id, { decimals: 2 });
                }}
                label="Two decimal"
                name={`decimal-config-${metric._id}`}
              />
            </div>
          )}
        </SavedMetricProperty>
      )}
    </>
  );
};
