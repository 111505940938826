import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useModal } from 'components/modals/ModalContext/modal.context';
import BuButton, { BuControlSize } from 'components/UI/BuButton';
import TooltipWrapper from 'components/UI/common/TypedTable/renderers/common/TooltipWrapper';
import BuIcon from 'components/UI/BuIcon';
import { getFeatureFlags } from 'selectors';

import * as styles from './styles';
import {
  FollowUpEmailData,
  FollowUpEmailModal as FollowUpEmailModalType,
} from '../../types';
import {
  getIsTaskCompleted,
  getStatusAndDateLabelForTaskModal,
} from '../../helpers';

export const FollowUpEmailModalTitle: React.FC = () => {
  const { getPropsForScheme } = useModal<FollowUpEmailModalType>();

  const { salesProcessItem } = getPropsForScheme('/follow-up-email') || {};

  const statusAndDateLabel =
    getStatusAndDateLabelForTaskModal(salesProcessItem);

  return (
    <div className={styles.followUpEmailModalTitle}>
      <div>
        Follow Up Email
        <TooltipWrapper
          tooltip={
            <div style={{ textAlign: 'center' }}>
              You can copy our AI generated email content including recipients,
              subject and body to clipboard, making it available for pasting in
              your preferred email app. Please make sure to review the body
              contents (and signature) before sending!
            </div>
          }
          position="bottom center"
        >
          <div>
            <BuIcon
              name={BoostUpIcons.BadgeInfoOutline}
              color="var(--bu-gray-900)"
            />
          </div>
        </TooltipWrapper>
      </div>

      {!!statusAndDateLabel && (
        <div className="last-updated-gen-ai">
          Last update by Gen AI on {statusAndDateLabel}
        </div>
      )}
    </div>
  );
};

const FollowUpEmailModal: React.FC = () => {
  const { getPropsForScheme, closeModal } = useModal<FollowUpEmailModalType>();
  const { task_completion_enabled } = useSelector((state) =>
    getFeatureFlags(state)
  );

  const [isSaving, setIsSaving] = useState(false);

  const {
    salesProcessItem: { task = {}, owner_id, id: task_id, status },
    user_id,
    completeTaskMutation,
  } = getPropsForScheme('/follow-up-email') || {};

  const {
    recipients = [],
    subject = '',
    body = '',
  } = task as FollowUpEmailData;

  const formattedRecipients = useMemo(
    () => recipients && recipients.join(', '),
    [recipients]
  );

  const getIsSalesProcessOwner = () => owner_id === user_id;

  const isTaskCompleted = getIsTaskCompleted(status);

  const showCompleteTaskButton =
    getIsSalesProcessOwner() && !isTaskCompleted && !!task_completion_enabled;

  const splittedBody = useMemo(() => body.split('\n'), [body]);

  const copyToClipboard = async (
    contextName: 'body' | 'recipients' | 'subject',
    textToCopy: string
  ) => {
    if (textToCopy) {
      try {
        await navigator.clipboard.writeText(textToCopy);
        toast.success(`Email ${contextName} copied to the clipboard.`, {
          position: 'bottom-left',
        });
      } catch {
        toast.error(`Failed to copy ${contextName} to the clipboard.`, {
          position: 'bottom-left',
        });
      }
    }
  };

  const handleCopyEmailAndCompleteTask = async () => {
    setIsSaving(true);

    await copyToClipboard('body', body);

    if (getIsSalesProcessOwner() && !isTaskCompleted) {
      await completeTaskMutation.mutateAsync({
        task_id: task_id,
        isTaskCompleted,
      });
    }

    setIsSaving(false);
    closeModal('/follow-up-email', () => ({}));
  };

  return (
    <div className={styles.followUpEmailModalWrapper}>
      {!!formattedRecipients && (
        <div className="recipients">
          <span className="key">To:</span>
          <span>{formattedRecipients}</span>

          <TooltipWrapper tooltip="Copy recipients to the clipboard">
            <BuButton
              size={BuControlSize.SMALL}
              secondary
              borderless
              icon
              onClick={() => copyToClipboard('recipients', formattedRecipients)}
            >
              <BuIcon name={BoostUpIcons.Copy} />
            </BuButton>
          </TooltipWrapper>
        </div>
      )}

      {!!subject && (
        <div className="subject">
          <span className="key">Subject:</span>
          <span>{subject}</span>

          <TooltipWrapper tooltip="Copy subject to the clipboard">
            <BuButton
              size={BuControlSize.SMALL}
              secondary
              borderless
              icon
              onClick={() => copyToClipboard('subject', subject)}
            >
              <BuIcon name={BoostUpIcons.Copy} />
            </BuButton>
          </TooltipWrapper>
        </div>
      )}

      <div className="body">
        {splittedBody.map((line, index) => (
          <React.Fragment key={index}>
            {line}
            {index < splittedBody.length - 1 && <br />}
          </React.Fragment>
        ))}
      </div>

      <div className="action-button">
        <TooltipWrapper tooltip="Copy body to the clipboard">
          <BuButton
            size={BuControlSize.REGULAR}
            secondary={showCompleteTaskButton}
            onClick={() => copyToClipboard('body', body)}
          >
            Only Copy Email
          </BuButton>
        </TooltipWrapper>

        {showCompleteTaskButton && (
          <BuButton
            size={BuControlSize.REGULAR}
            disabled={isSaving}
            onClick={handleCopyEmailAndCompleteTask}
          >
            Copy Email and Complete Task
          </BuButton>
        )}
      </div>
    </div>
  );
};

export default FollowUpEmailModal;
