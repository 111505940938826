import moment from 'moment';

import { AnalysisType } from 'components/dashboard/Metrics/constants';
import {
  AggregationFunction,
  MetricType,
} from 'components/dashboard/Metrics/enums';
import { BIWidget } from 'components/dashboard/Metrics/metrics.types';

export const NEW_WIDGET_MOCK: BIWidget = {
  name: '',
  chart_type: 'column',
  analysis_type: AnalysisType.LIVE,
  point_in_time: '',
  metric_list: [],
  template_filters: [],
  widget_filters: [],
  order_by: ['descending'],
  order_by_column: {
    name: '',
    label: '',
    type: '',
  },
  group_by: [],
  limit: 10,
  compute_user_hierarchy_response: true,
  advanced_configurations: {
    remove_reportees_data_from_managers: true,
  },
};

export const FUNNEL_PREDEFINED_METRICS = [
  {
    _id: '__FUNNEL__OBJ_COUNT',
    name: 'Deal Count',
    description: 'Number of deals in the stage',
    aggregation_function: AggregationFunction.Count,
    column: {
      name: 'opportunity.opportunity_name',
      label: 'Opportunity Name',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__OBJ_COUNT_CONVERSION',
    name: 'Stage Conversion Rate (Count)',
    description: 'Conversion rate on the number of deals in the stage',
    aggregation_function: AggregationFunction.Count,
    column: {
      name: 'opportunity.opportunity_name',
      label: 'Opportunity Name',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__OBJ_AMOUNT',
    name: 'Deal Amount',
    description: 'Total amount of deals in the stage',
    aggregation_function: AggregationFunction.Sum,
    column: {
      name: 'opportunity.amount',
      label: 'Amount',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__OBJ_AMOUNT_CONVERSION',
    name: 'Stage Conversion Rate (Amount)',
    description: 'Conversion rate on the amount of deals in the stage',
    aggregation_function: AggregationFunction.Sum,
    column: {
      name: 'opportunity.amount',
      label: 'Amount',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__AVG_DAYS',
    name: 'Average Days',
    description:
      'Average number of days between entering and exiting the stage',
    aggregation_function: AggregationFunction.Average,
    column: {
      name: '',
      label: 'Days in Stage',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__WIN_RATE_COUNT',
    name: 'Win Rate (count)',
    description: 'Conversion rate of deals that went into the last stage',
    aggregation_function: AggregationFunction.Count,
    column: {
      name: '',
      label: 'Days in Stage',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__WIN_RATE_AMOUNT',
    name: 'Win Rate (amount)',
    description: 'Conversion rate of the amount that went into the last stage',
    aggregation_function: AggregationFunction.Sum,
    column: {
      name: '',
      label: 'Amount',
      type: 'text',
    },
  },
].map((metric) => ({
  ...metric,
  is_independent: true,
  company_id: 'NONE',
  created_at: moment().toISOString(),
  updated_at: moment().toISOString(),
  analysis_type: AnalysisType.FUNNEL,
  filters: [],
  metadata: { type: MetricType.Simple },
  object: 'opportunity',
}));

export const FUNNEL_PREDEFINED_TOP_METRICS = [
  {
    _id: '__FUNNEL__DEAL_COUNT_OVERALL',
    name: 'Overall Conversion Rate',
    description: 'Conversion Rate of entire funnel',
    aggregation_function: AggregationFunction.Count,
    column: {
      name: 'opportunity.opportunity_name',
      label: 'Opportunity Name',
      type: 'text',
    },
  },
  {
    _id: '__FUNNEL__AVG_DEAL_CYCLE',
    name: 'Average Days in Funnel',
    description:
      'Average number of days between entering and exiting the funnel',
    aggregation_function: AggregationFunction.Average,
    column: {
      name: 'avg_days_in_funnel',
      label: 'Days in stage',
      type: 'text',
    },
  },
].map((metric) => ({
  ...metric,
  is_independent: true,
  company_id: 'NONE',
  created_at: moment().toISOString(),
  updated_at: moment().toISOString(),
  analysis_type: AnalysisType.FUNNEL,
  filters: [],
  metadata: { type: MetricType.Simple },
  object: 'opportunity',
}));
