import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { TABLE_ID } from 'common/constants';
import BuButton from 'components/UI/BuButton';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import Table from 'components/UI/TableConfig/Table';
import { IColumn, IRow } from 'components/UI/common/TypedTable/TypedTable';
import {
  getMetricsListTableColumns,
  getWidgetsListTableColumns,
} from 'components/dashboard/Metrics/List/Common/Columns/ListTableColumns';
import {
  DeleteInfoContainer,
  Header,
  HeaderButton,
  HeaderDescription,
  HeaderText,
  HeaderTitle,
  MainContainer,
  TableCont,
} from 'components/dashboard/Metrics/List/Common/styles';
import { MetricEditConfirmationPopup } from 'components/dashboard/Metrics/common/MetricEditConfirmationPopup/MetricEditConfirmationPopup';
import {
  BIMetricFormula,
  BIMetricSimple,
  BIWidget,
  DeleteInfo,
  MetricDeleteInfo,
  SelectedItem,
} from 'components/dashboard/Metrics/metrics.types';
import useTableSearch from 'components/hooks/useTableSearch';
import useTableSort from 'components/hooks/useTableSort';
import { fetchApi, QueryStatus } from 'utils/network';
import { useLocalPagination } from 'components/UI/TableConfig/TableControls';

interface Props {
  isMetrics?: boolean;
  list: (BIMetricSimple | BIMetricFormula)[] | BIWidget[];
  status: QueryStatus;
  selectedElement?: SelectedItem;
  onDeleteConfirm: (affectedEntitiesIds?: string[]) => void;
  onClone: (id: string, name: string) => void;
  setSelectedElement: Dispatch<SetStateAction<SelectedItem | undefined>>;
}

export const CommonList: React.FC<Props> = ({
  isMetrics = false,
  list,
  status,
  selectedElement,
  onDeleteConfirm,
  onClone,
  setSelectedElement,
}) => {
  const history = useHistory();

  const [sortByField, setSortByField, sortBy] = useTableSort<IRow>();
  const [, setSearchText, searchFilter] = useTableSearch<IRow>([['name']]);

  const [dashboardDeletedList, setDashboardDeletedList] = useState<string[]>();
  const [metricDeleteInfo, setMetricDeleteInfo] = useState<MetricDeleteInfo>();
  const [rows, setRows] = useState<IRow[]>((list as IRow[]) ?? []);
  const [filteredRows, setFilteredRows] = useState<IRow[]>(
    (list as IRow[]) ?? []
  );
  const [isDelConfirmationOpen, setIsDelConfirmationOpen] =
    useState<boolean>(false);
  const [isEditConfirmationOpen, setIsEditConfirmationOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (rows.length) {
      setFilteredRows(sortBy(rows.filter(searchFilter)));
    }
  }, [rows, sortBy, searchFilter]);

  const pagination = useLocalPagination(filteredRows, 20);

  useEffect(() => {
    setRows((list as IRow[]) ?? []);
    setFilteredRows((list as IRow[]) ?? []);
  }, [list]);

  const hasMetricDeleteInfo =
    metricDeleteInfo &&
    (metricDeleteInfo.widget_deleted_list.length > 0 ||
      metricDeleteInfo?.synthetic_metric_list.length > 0);

  const handleCreate = (): void => {
    history.push(isMetrics ? '/revbi/metrics/create' : '/revbi/widgets/create');
  };

  const handleEditConfirm = (id: string): void => {
    history.push(
      isMetrics ? `/revbi/metrics/edit/${id}` : `/revbi/widgets/edit/${id}`
    );
  };

  const handleDelete = (id: string, name: string): void => {
    const deleteInfoAPI = isMetrics
      ? `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics_delete_info/${id}`
      : `${process.env.REACT_APP_BACKEND_URL}/rev_bi/widgets_delete_info/${id}`;
    fetchApi<void, DeleteInfo>({
      url: deleteInfoAPI,
      queryMethod: 'get',
      setData: (result) => {
        if (isMetrics) {
          setMetricDeleteInfo(result as MetricDeleteInfo);
        } else {
          setDashboardDeletedList(result as string[]);
        }
        setIsDelConfirmationOpen(true);
        setSelectedElement({ id, name });
      },
      setError: (_: string | null) => {
        toast.error(`Failed to check if safe to delete metric`);
      },
    });
  };

  const handleEdit = (id: string, name: string): void => {
    if (isMetrics) {
      fetchApi<void, DeleteInfo>({
        url: `${process.env.REACT_APP_BACKEND_URL}/rev_bi/metrics_delete_info/${id}`,
        queryMethod: 'get',
        setData: (result) => {
          setMetricDeleteInfo(result as MetricDeleteInfo);
          if (
            (result as MetricDeleteInfo).widget_deleted_list.length > 0 ||
            (result as MetricDeleteInfo)?.widget_edited_list.length > 0 ||
            (result as MetricDeleteInfo)?.synthetic_metric_list.length > 0
          ) {
            setIsEditConfirmationOpen && setIsEditConfirmationOpen(true);
            setSelectedElement({ id, name });
          } else {
            handleEditConfirm(id);
          }
        },
        setError: (_: string | null) => {
          toast.error(`Failed to check if safe to edit metric`);
        },
      });
    } else {
      handleEditConfirm(id);
    }
  };

  const columns: IColumn[] = isMetrics
    ? getMetricsListTableColumns(handleEdit, handleDelete, onClone)
    : getWidgetsListTableColumns(handleEdit, handleDelete, onClone);

  return (
    <MainContainer>
      <Header>
        <HeaderText>
          <HeaderTitle>{isMetrics ? 'BI Metrics' : 'BI Widgets'}</HeaderTitle>
          <HeaderDescription>
            {isMetrics
              ? 'Define metrics to be used in dashboard widgets'
              : 'Define your own widgets to use in dashboards or generate reports'}
          </HeaderDescription>
        </HeaderText>
        <HeaderButton>
          <BuButton onClick={handleCreate}>
            Create {isMetrics ? 'Metric' : 'Widget'}
          </BuButton>
        </HeaderButton>
      </Header>

      <TableCont>
        <Table
          tableId={isMetrics ? TABLE_ID.REVBI_METRICS : TABLE_ID.REVBI_WIDGETS}
          fullscreen
          hidePaginationEnd
          title={isMetrics ? 'Metrics' : 'Widgets'}
          columns={columns}
          data={pagination.data}
          rowsPerPage={pagination.rowsPerPage}
          currentPage={pagination.currentPage}
          totalCount={pagination.totalCount}
          loading={status === 'loading'}
          sortOrder={sortByField}
          onSearchChange={setSearchText}
          onSort={setSortByField}
          onPaginationChange={pagination.onPaginationChange}
          searchPlaceholder={`Search ${isMetrics ? 'metrics' : 'widgets'}`}
        />
      </TableCont>

      {isDelConfirmationOpen && (
        <BuConfirmationPopup
          cancelText={hasMetricDeleteInfo ? 'Cancel' : 'No'}
          confirmText={
            hasMetricDeleteInfo ? 'Delete Metric and listed items' : 'Yes'
          }
          headerText={
            hasMetricDeleteInfo
              ? 'Metric used in existing widgets'
              : 'Confirmation Required!'
          }
          isOpen={isDelConfirmationOpen}
          onClose={() => {
            setIsDelConfirmationOpen(false);
          }}
          onConfirm={() => {
            setIsDelConfirmationOpen(false);
            const affectedEntitiesIds =
              metricDeleteInfo?.synthetic_metric_list?.map((e) => e.object_id);
            onDeleteConfirm(affectedEntitiesIds);
          }}
        >
          {dashboardDeletedList && dashboardDeletedList?.length > 0 ? (
            <>
              <div>
                '{selectedElement?.name}' is being used in existing dashboards
                and deleting it will impact them.
              </div>
              <span>Are you sure you want to continue?</span>
            </>
          ) : hasMetricDeleteInfo ? (
            <DeleteInfoContainer>
              <div>
                Deleting this metric can leave widgets and synthetic metrics
                that use it in an inconsistent state, causing them to also be
                deleted in the process.
              </div>
              <div>
                To safely delete this metric consider removing it from the
                following:
              </div>

              {metricDeleteInfo?.widget_deleted_list &&
                metricDeleteInfo.widget_deleted_list.length > 0 && (
                  <div>
                    <span>Widgets</span>
                    <ul>
                      {metricDeleteInfo.widget_deleted_list.map((widget) => (
                        <li key={widget.object_id}>
                          <a href={`/revbi/widgets/edit/${widget.object_id}`}>
                            {widget.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              {metricDeleteInfo?.synthetic_metric_list &&
                metricDeleteInfo.synthetic_metric_list.length > 0 && (
                  <div>
                    <span>Metrics:</span>
                    <ul>
                      {metricDeleteInfo.synthetic_metric_list.map((metric) => (
                        <li>
                          <a href={`/revbi/metrics/edit/${metric.object_id}`}>
                            {metric.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
            </DeleteInfoContainer>
          ) : metricDeleteInfo?.widget_edited_list &&
            metricDeleteInfo?.widget_edited_list.length > 0 ? (
            `’${selectedElement?.name}’ is being used in existing widgets and deleting it will impact them. Are you sure you want to continue? It will be permanently removed.`
          ) : (
            `Are you sure you want to delete '${selectedElement?.name}'? It will be permanently removed.`
          )}
        </BuConfirmationPopup>
      )}

      {isEditConfirmationOpen && (
        <MetricEditConfirmationPopup
          isEditConfirmationOpen={isEditConfirmationOpen}
          selectedElement={selectedElement}
          metricDeleteInfo={metricDeleteInfo}
          setIsEditConfirmationOpen={setIsEditConfirmationOpen}
          onEditConfirm={handleEditConfirm}
        />
      )}
    </MainContainer>
  );
};
