import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

import { CompanyUserUpdateFields } from 'actions/companyUsersActions';
import BuConfirmationPopup from 'components/UI/BuConfirmationPopup';
import CloseButton from 'components/UI/CloseButton';
import { IRow } from 'components/UI/common/TypedTable/TypedTable';
import { IDropDownOptions } from 'components/UI/common/TypedTable/renderers/DropDownCell';
import SFUserList from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/SFUserList';
import UserReviewList from 'components/settings/ManageUsers/UsersAddView/AddUserSteps/UserReviewList';
import * as s from 'components/settings/ManageUsers/UsersAddView/styles';
import { IProps } from 'components/settings/ManageUsers/UsersAddView/types';
import { typedRows } from 'components/settings/ManageUsers/helpers';
import { RowField } from 'components/settings/ManageUsers/types';
import { useInvalidateCompanyUsers } from 'hooks/useManageCompanyUsers';

const UsersAddView: React.FC<IProps> = ({
  managers,
  isCreated,
  createUsers,
  onClose,
}: IProps) => {
  const [managersOptions, setManagersOptions] = useState<IDropDownOptions[]>(
    []
  );
  const [reviewRows, setReviewRows] = useState<IRow[]>([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const invalidateCompanyUsers = useInvalidateCompanyUsers();

  const [step, setStep] = useState(0);

  useEffect(() => {
    setReviewRows([]);
  }, []);

  useEffect(() => {
    if (isCreated) {
      invalidateCompanyUsers();

      onClose();
    }
  }, [isCreated]);

  useEffect(() => {
    setManagersOptions([
      {
        key: 'none',
        text: 'None',
        value: '',
      },
      ...managers
        .filter((item) => item.status === 'active')
        .map((item) => ({
          key: item.email,
          text: item.name || item.email,
          value: item.email,
          disabled: item.status === 'disabled',
        })),
    ]);
  }, [managers]);

  const handleAdd = (rows: IRow[]) => {
    setReviewRows(rows);
    setModalVisible(true);
  };

  const handleSaveUsers = () => {
    createUsers(
      typedRows(reviewRows).map<CompanyUserUpdateFields>((item) => {
        const user = item as RowField;

        return {
          is_user_impersonation_allowed: user.is_user_impersonation_allowed,
          sfdc_user_id: user.user_id,
          email: user.email,
          role: user.role,
          status: user.status,
          manager: isEmpty(user.manager) ? undefined : user.manager,
          profileId: user.profileId,
          user_settings: {
            processing: {
              emails: false,
              calls: false,
              calendar: false,
              ...user.user_settings?.processing,
            },
          },
        };
      })
    );

    setModalVisible(false);
  };

  const showReview = (rows: IRow[]) => {
    setReviewRows(rows);
    setStep(1);
  };

  return (
    <div className={classNames(s.container, s.fullscreen)}>
      <div className={s.header}>
        <CloseButton onClick={onClose} style={{ marginRight: 10 }} />
        Add Users
      </div>

      {step === 0 && (
        <SFUserList
          managersOptions={managersOptions}
          checked={reviewRows}
          onPrevStepClick={onClose}
          onNextStepClick={showReview}
        />
      )}

      {step === 1 && (
        <UserReviewList
          managersOptions={managersOptions}
          data={reviewRows}
          onPrevStepClick={() => setStep(0)}
          onNextStepClick={handleAdd}
        />
      )}

      <BuConfirmationPopup
        cancelText="No"
        confirmText="Yes"
        headerText="Confirmation Required!"
        isOpen={isModalVisible}
        onClose={() => setModalVisible(false)}
        onConfirm={handleSaveUsers}
      >
        <p>
          Do you want to proceed with adding {reviewRows.length} new{' '}
          {reviewRows.length > 1 ? 'users' : 'user'} to the boostup?
        </p>
      </BuConfirmationPopup>
    </div>
  );
};

export default UsersAddView;
