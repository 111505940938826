import sortBy from 'lodash/sortBy';

import { UserResponse } from 'reducers/companyUsersReducer';
import { IReduxState } from 'reducers/types';

export const getCompanyUserDetails = (state: IReduxState) =>
  state.companySettings.companyUser;

export const getCompanyUserDisabledFields = (state: IReduxState) =>
  state.companySettings.companyUserDisabledFields;

export const getCompanyUserIsLoading = (state: IReduxState) =>
  state.companySettings.companyUserLoading;
export const getUnmappedUserIsLoading = (state: IReduxState) =>
  state.companySettings.unmappedUserLoading;

export const isCompanyUserCreated = (state: IReduxState) =>
  state.companySettings.companyAddUsersStatuses;

export const getCompanyManagerList = (state: IReduxState) =>
  sortBy(
    Object.values<UserResponse>(state.companySettings.companyManagers.users),
    ['status', 'name']
  );

export const getCompanyManagerTeamList = (state: IReduxState) =>
  sortBy(
    Object.values<UserResponse>(
      state.companySettings.companyManagerTeams.users
    ),
    ['status', 'name']
  );

export const getUnmappedUserList = (state: IReduxState) =>
  state.companySettings.unmappedUsers;

export const getEmailCalendarSyncPopupData = (state: IReduxState) =>
  state.companySettings.emailCalendarSyncPopup;
